import React from "react"
import LatestSermonVideo from "./LatestSermonVideo"
import { useStaticQuery, graphql } from "gatsby"

const SermonHero = () => {
  const { youtubeVideo } = useStaticQuery(graphql`
    {
      youtubeVideo(description: { regex: "/kazanie/" }) {
        videoId
        thumbnail {
          url
        }
      }
    }
  `)

  return (
    <section className="bg-gray-300">
      <div className="container py-24">
        <h2
          className="text-center mt-12 uppercase  font-bold sm:text-4xl"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Ostatnie Kazanie
        </h2>
        <div
          className="max-w-screen-xl mx-auto mt-12 "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="grid grid-cols-12">
            <div className="lg:col-span-8 lg:col-start-3 col-span-12 col-start-1">
              <LatestSermonVideo
                video={youtubeVideo.videoId}
                imageUrl={youtubeVideo.thumbnail.url}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SermonHero
