import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LatestSermonVideo from "./LatestSermonVideo"

const SermonList = () => {
  const { allYoutubeVideo } = useStaticQuery(graphql`
    {
      allYoutubeVideo(
        filter: { description: { regex: "/#kazanie/" } }
        skip: 1
        limit: 6
      ) {
        edges {
          node {
            videoId
            thumbnail {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <div className="container grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-12">
      {allYoutubeVideo.edges.map(({ node }) => (
        <div>
          <LatestSermonVideo
            video={node.videoId}
            imageUrl={node.thumbnail.url}
          />
        </div>
      ))}
    </div>
  )
}

export default SermonList
