import React from "react"
import { AiFillPlayCircle } from "react-icons/ai"
import styled from "styled-components"

const StyledIcon = styled(AiFillPlayCircle)`
  position: absolute;

  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: white;
`

const StyledImage = styled.img`
  filter: brightness(50%);
`

const StyledLink = styled.a`
  display: block;
  transition: transform 0.3s;
  position: relative;
  &:hover {
    transform: translateY(-5%);
  }
`

const LatestSermonVideo = ({ video, imageUrl }) => {
  return (
    <StyledLink
      href={`https://www.youtube.com/watch?v=${video}`}
      target="_blank"
    >
      <StyledImage src={imageUrl} />
      <StyledIcon />
    </StyledLink>
  )
}

export default LatestSermonVideo
