import React from "react"
import LastSermons from "../components/Sermons/LastSermons"
import Helmet from "react-helmet"

import SermonHero from "../components/Sermons/SermonHero"
import PageTemplate from "../templates/PageTemplate"

const Sermons = () => {
  return (
    <PageTemplate>
      <Helmet title="Moja oaza - Kazania" />
      <SermonHero />
      <LastSermons />
    </PageTemplate>
  )
}

export default Sermons
