import React from "react"
import SermonList from "./SermonList"

const LastSermons = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-24">
      <h2
        className="text-center uppercase  font-bold sm:text-4xl"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Poprzednie Kazania
      </h2>
      <SermonList />
    </div>
  )
}

export default LastSermons
